import { IObjectManualData } from './manual-data.enum';
import { INavigationObject, INavigationProperties } from './navigation';
import { ObjectProps } from './object-property-api';
import { VisualizationPackageJson } from './visualization';

export interface Sheet {
  id: string;
  deletedAt?: Date;
  name: string;
  visible: boolean;
  order: number;
  workbookId: string;
  rootContainerId: string;
  parentId: string;
  ownerId: string;
}

export interface SheetObject {
  id: string;
  workbookId: string;
  type: string;
  properties: GenericObjectProperties;
  meta: IObjectMeta;
  databaseId: string;
  visibilityVariableId: string | null;
  name: string;
  sheetId: string;
  sheetName?: string;
}

export interface ChartObject<T = GenericObjectProperties> extends Omit<SheetObject, 'properties' | 'type'> {
  id: string;
  itemId: string;
  workbookId: string;
  type: string;
  properties: T;
  meta: IObjectMeta;
  version: string;
  url: string;
  package: VisualizationPackageJson;
  databaseId: string;
  visibilityVariableId: string | null;
  manualData?: IObjectManualData;
  object: string;
  references?: ExternalReference[];
  updated: string;
  objectProperties: {
    backgroundColor: string;
    aspectRatio: AspectRatioProperties;
    item: Partial<ObjectProps>;
  };
}

export interface ContainerObject extends Omit<SheetObject, 'properties'> {
  properties: ContainerProperties;
  type: 'container';
}

export interface GroupObject extends Omit<ChartObject<GroupProperties>, 'type'> {
  type: 'group';
}

export interface ContainerGroupObject extends Omit<SheetObject, 'properties'> {
  properties: ContainerGroupProperties;
  type: InMemoryObjectType.CONTAINER_GROUP;
}

export type DaedalusObject = ChartObject | ContainerObject | GroupObject | ContainerGroupObject;

export interface GenericObjectProperties {
  [key: string]: unknown;
}

export interface VSpec {
  [key: string]: unknown;
}

export interface AspectRatioProperties {
  enabled: boolean;
  ratioHeight?: number;
  ratioWidth?: number;
  canvasSizing?: CanvasSizing;
}

export interface ContainerProperties extends GenericObjectProperties {
  backgroundColor: string;
  item: {
    borderSize: number;
    borderColor: string;
    borderRadius: number;
    borderType: string;
    padding: number;
  };
  filterbar: { type: FilterBarType };
  aspectRatio: AspectRatioProperties;
  children: ObjectChild[];
}

export interface GroupProperties extends GenericObjectProperties {
  children: ObjectChild[];
}

export interface INavigationNode {
  id: string;
  name: string;
  objectId: string;
  parentId?: string;
  visible?: boolean;
}

export interface HierarchicalNode extends Omit<INavigationNode, 'parentId'> {
  children?: HierarchicalNode[];
}

export interface IWorkbookNavigation {
  navigationObjects: INavigationObject[];
  properties: INavigationProperties;
}
export interface ContainerGroupProperties extends GenericObjectProperties {
  children: INavigationNode[];
  navigation: IWorkbookNavigation;
}

export interface INavigationNode {
  id: string;
  name: string;
  objectId: string;
  parentId?: string;
  visible?: boolean;
  order: number;
}

export interface ObjectChildContainer {
  location: { x: number; y: number; z: number };
  size: { height: number; width: number };
  rotation: number;
}

export interface ObjectChild {
  id: string;
  type: string;
  position: {
    container: ObjectChildContainer;
  };
  object: string;
  objectEntity: ChartObject | GroupObject;
  properties: {
    backgroundColor: string;
    aspectRatio: AspectRatioProperties;
    item: Partial<ObjectProps>;
  };
}

export interface SaveObjectPropertiesConfig {
  ignoreHistoryEntry?: boolean;
}

export interface IObjectMetaField {
  value: string;
  visible: boolean;
}

export interface IObjectMeta {
  title: IObjectMetaField;
  subtitle: IObjectMetaField;
  footnote: IObjectMetaField;
}

export interface IObjectVariableVisibility {
  visibilityVariableId: string | null;
}

export interface ObjectMeta {
  id: ChartObject['id'];
  type: ChartObject['type'];
}

export interface ObjectLayout {
  properties: GenericObjectProperties;
  meta: ObjectMeta;
}

export interface ObjectSize {
  width: number;
  height: number;
}

export enum InMemoryObjectType {
  Group = 'group',
  FILTERBAR = 'astrato-filter-pane',
  CONTAINER_GROUP = 'container-group',
}

export enum ControlTypeObjects {
  FILTER = 'filter',
  INPUT_BOX = 'input-box',
  ATOMIC_INPUT = 'atomic-input',
  BUTTON = 'button',
}

export enum FilterTypeObjects {
  FILTER = 'filter',
  SLIDER = 'slider',
}

export enum ObjectPropertyType {
  number = 'Number',
  string = 'String',
  boolean = 'Boolean',
  borderType = 'BorderType',
}

export enum FilterBarType {
  Discreet = 'discreet',
  FilterBar = 'filter-bar',
  None = 'none',
}

export enum CanvasSizing {
  None = 'none',
  FullWidth = 'fullWidth',
  FullHeight = 'fullHeight',
}

export interface ExternalReference {
  referenceType: 'variable' | 'media';
  referenceKey: string;
  oldReferenceKey?: string;
}
