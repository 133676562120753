export enum ImportType {
  BOOTSTRAP = 'bootstrapComponent',
  FLOATING_PANEL = 'floatingPanel',
  SETTINGS_PANEL = 'settingsPanel',
  BOARD_PANEL = 'boardPanel',
}

export enum ItemType {
  // root container
  ROOT = 'root',

  // containers
  CANVAS = 'container',
  GROUP = 'group',
  CONTAINER_GROUP = 'container-group',

  // charts
  PENDING = 'pending',

  // UI Only!
  MULTISELECT = 'multiselect',

  FILTERBAR = 'astrato-filter-pane',
}

export enum ComponentType {
  visualization = 'visualization',
  textObject = 'text',
  control = 'control',
  filter = 'filter',
  shape = 'shape',
  image = 'image',
  panel = 'panel',
}
