export enum CopilotSupportedModels {
  GPT_35_TURBO = 'gpt-35-turbo',
}

export enum CopilotInsightsTask {
  Summarize = 'Summarize',
  Trends = 'Trends',
  CompareTwoCharts = 'Compare Two Charts',
  PatternsAndOutliers = 'Patterns & outliers',
  SurpriseMe = 'Surprise me',
}

export enum CopilotInsightsTone {
  Formal = 'Formal',
  Informal = 'Informal',
}

export enum CopilotInsightsLanguage {
  English = 'en-gb',
  French = 'fr',
  German = 'de',
  Spanish = 'es',
  Portuguese = 'pt',
  Polish = 'pl',
  Swedish = 'sv',
  Ukrainian = 'uk',
  Hindi = 'hi',
}

export enum CopilotInsightsLength {
  Short = 'Short',
  Medium = 'Medium',
  Long = 'Long',
}

export enum CopilotInsightsStructure {
  Summary = 'summary',
  Report = 'report',
  BulletList = 'bullet-list',
}

export enum CopilotDashboardInsightsChartType {
  BarChart = 'bar-chart',
  ComboChart = 'combo-chart',
  DistributionGaugeChart = 'distribution-gauge-chart',
  GaugeChart = 'gauge-chart',
  HeatmapChart = 'heat-map-chart',
  Histogram = 'histogram',
  LineChart = 'line-chart',
  PieChart = 'pie-chart',
  ScatterChart = 'scatter-chart',
  Table = 'table',
  Sankey = 'charts-sankey',
  Waterfall = 'charts-waterfall',
  WordCloud = 'charts-word-cloud',
  DotChart = 'charts-dot',
  SelfService = 'self-service',
}

export enum CopilotGenerateDashboardSupportedVisualisationType {
  BAR_CHART = 'bar-chart',
  COMBO_CHART = 'combo-chart',
  DISTRIBUTION_GAUGE_CHART = 'distribution-gauge-chart',
  GAUGE_CHART = 'gauge-chart',
  LINE_CHART = 'line-chart',
  TABLE = 'table',
  SCATTER_CHART = 'scatter-chart',
  HEATMAP = 'heat-map-chart',
}

export enum CopilotGenerateDashboardSupportedFormat {
  FLOAT = 'float',
  STRING = 'string',
  INT = 'int',
  DATE = 'date',
  TIMESTAMP = 'timestamp',
}

export enum CopilotGenerateDashboardSupportedAggregation {
  ANYVALUE = 'ANYVALUE',
  AVG = 'AVG',
  COUNT = 'COUNT',
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  MIN = 'MIN',
  MAX = 'MAX',
  MEDIAN = 'MEDIAN',
  SUM = 'SUM',
  VARIANCE = 'VARIANCE',
}
